import React, { useState, useEffect, useContext } from 'react'
import { navigate } from 'gatsby'

import Layout from '../components/Layout'
import { isBrowser, hasRole } from '../components/Auth'

import {
  getPermissions,
  putPermissionRole,
  getPermissionRole
} from '../services/api/permissions'

import { toast } from 'react-toastify'
import { getRoles } from '../services/api/roles'
import Checkbox from '../components/Checkbox'
import { Context } from '../Context'

const PermissionsPage = () => {
  const { isAuth } = useContext(Context)

  if (!isAuth) {
    if (isBrowser()) {
      navigate('/dashboard/')
    }
    return (
      <Layout withoutHeader className='page-permissions'>
        Cargando...
      </Layout>
    )
  }

  const [permissions, setPermissions] = useState([])
  const [roles, setRoles] = useState([])
  const [permissionRole, setPermissionRole] = useState([
    { permission_id: '1', role_id: '1' }
  ])

  // get permissions, roles and permission_role
  useEffect(() => {
    getPermissions()
      .then(res => {
        if (res.status === 200) {
          setPermissions(res.data)
        }
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })

    getRoles()
      .then(res => {
        if (res.status === 200) {
          setRoles(res.data)
        }
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })

    getPermissionRole()
      .then(res => {
        if (res.status === 200) {
          const data = res.data.map(i => {
            return {
              permission_id: `${i.permission_id}`,
              role_id: `${i.role_id}`
            }
          })

          setPermissionRole(data)
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }, [])

  // el evento corresponde al checkbox del rol
  function handleChange (e) {
    const role_id = e.target.value
    const permission_id = e.target.dataset.permission
    let pr = [...permissionRole]

    if (e.target.checked) {
      pr = [
        ...pr,
        {
          permission_id,
          role_id
        }
      ]
    } else {
      pr = permissionRole.filter(i => {
        if (i.role_id === role_id && i.permission_id === permission_id) {
          return false
        }
        return true
      })
    }

    setPermissionRole(pr)
  }

  // si está en permissionRole retorna true
  function handleChecked (role_id, permission_id) {
    const finded = permissionRole.findIndex(pr => {
      return (
        pr.role_id === `${role_id}` && pr.permission_id === `${permission_id}`
      )
    })

    return finded !== -1
  }

  function submitPermissions () {
    const values = permissionRole.map(i => {
      return {
        permission_id: parseInt(i.permission_id, 10),
        role_id: parseInt(i.role_id, 10)
      }
    })

    putPermissionRole(values)
      .then(res => {
        if (res.status === 200) {
          toast.success('Permisos actualizados')
        }
      })
      .catch(err => {
        console.dir(err)
        toast.error(`${err}`)
      })
  }

  if (!hasRole(1)) {
    if (isBrowser()) {
      navigate('/dashboard/')
    }
  }

  return (
    <Layout className='page-permissions'>
      <div style={{ color: 'white', margin: '0 auto', maxWidth: '1024px' }}>
        <h1 style={{ color: 'white', textAlign: 'center' }}>PERMISOS</h1>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
          }}
        >
          {permissions.map(permission => {
            return (
              <div key={permission.id} style={{ width: '30%', margin: '1rem' }}>
                <h2 style={{ color: 'white' }}>
                  {permission.id}. {permission.name}
                </h2>

                {roles.map(role => {
                  return (
                    <div key={`r-${role.id}`}>
                      <Checkbox
                        data-permission={permission.id}
                        value={role.id}
                        checked={handleChecked(role.id, permission.id)}
                        onChange={handleChange}
                      />
                      <span style={{ paddingLeft: '0.8em' }}>{role.name}</span>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>

        <button
          style={{
            backgroundColor: 'var(--color1)',
            margin: '3rem 0',
            padding: '0.5rem 1rem'
          }}
          onClick={submitPermissions}
        >
          GUARDAR CAMBIOS
        </button>
      </div>
    </Layout>
  )
}

export default PermissionsPage
