import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'
import querystring from 'querystring'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * @returns {Promise}
 */
export function getRoles (args) {
  const params = querystring.stringify(args)

  return axios.get(API('roles', params), {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Actualiza los roles de un usuario
 * @param {int} userId
 * @param {Array} roles
 * @returns {Promise}
 */
export function putUserRoles (userId, roles) {
  const values = roles.map(roleId => {
    return { user_id: parseInt(userId), role_id: roleId }
  })

  return axios.put(API('pivots', 'action=role_user'), { values }, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Elimina los roles de un usuario
 * @param {int} userId
 * @returns {Promise}
 */
export function deleteUserRoles (userId) {
  const data = {
    values: [{ user_id: parseInt(userId) }]
  }

  return axios.delete(API('pivots', 'action=role_user'), {
    headers: {
      Authorization: getJwtString()
    },
    data
  })
}
